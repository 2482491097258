import { AssignmentIndOutlined, Facebook, GitHub, Instagram, LinkedIn, WebOutlined } from "@mui/icons-material";

export default{
    name: 'Pramod Sharma',
    title: 'Frontend Developer',
    birthday: '2002/11/12',
    email: 'pramodchalise122@gmail.com',
    address: 'Nepal, Chitwan Bharatpur-23',
    phone: '+977 9824238746',


    socials:{
        LinkedIn:{
            link: 'https://www.linkedin.com/in/pramodsharma122/',
            text: 'pramodsharma122',
            icon: <LinkedIn />,
        },
        Facebook:{
            link: 'https://www.facebook.com/pramodsharma.122/',
            text: 'Přw-mod Shar-ma',
            icon: <Facebook />
        },
        Instagram:{
            link: 'https://www.instagram.com/prwmodsharma/',
            text: 'prwmodsharma',
            icon: <Instagram />
        },
        GitHub:{
            link: 'https://github.com/PramodSharma122',
            text: 'PramodSharma122',
            icon: <GitHub />,
        },
        
    },


    // services:[
    //     {
    //         title: 'web Dev',
    //         description:'I am hdhfdghbd',
    //         icon:<WebOutlined />
    //     },
    //     {
    //         title: 'web Dev',
    //         description:'I am hdhfdghbd',
    //         icon:<AssignmentIndOutlined />
    //     },
    //     {
    //         title: 'web Dev',
    //         description:'I am hdhfdghbd',
    //         icon:<WebOutlined />
    //     },
    // ],
    
};